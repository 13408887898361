import { useState } from "react";
import Card from "react-bootstrap/Card";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import { allCategories } from "./constants";

import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { db } from "./firebase";

export function MyItemList({ items, uid }) {
  const [currentCategory, setCurrentCategory] = useState("all");
  const [currentStore, setCurrentStore] = useState("all");

  const cycleStore = () => {
    if (currentStore === "all") {
      setCurrentStore("migros");
    } else if (currentStore === "migros") {
      setCurrentStore("coop");
    } else {
      setCurrentStore("all");
    }
  };

  const buttonContent = {
    all: <div style={{ width: "5rem", height: "1.5rem" }}>Alle Shops</div>,
    migros: (
      <img
        alt="migros-logo"
        className="object-contain"
        style={{ width: "5rem", height: "1.5rem" }}
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Migros.svg/1280px-Migros.svg.png"
      />
    ),
    coop: (
      <img
        alt="coop-logo"
        className="object-contain"
        style={{ width: "5rem", height: "1.5rem" }}
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Coop.svg/2560px-Coop.svg.png"
      />
    ),
  };

  return (
    <div className="ml-3 mr-3  mb-10">
      <ButtonToolbar className="justify-content-between">
        <ButtonGroup className="mr-3 mb-3">
          <Button variant="primary" onClick={cycleStore}>
            {buttonContent[currentStore]}
          </Button>
        </ButtonGroup>
        <ButtonGroup className="flex-wrap mb-3" size="sm">
          <Button
            variant={
              currentCategory === "all" ? "primary" : "outline-secondary"
            }
            onClick={() => setCurrentCategory("all")}
          >
            Alle
          </Button>
          {allCategories.map((category) => (
            <Button
              variant={
                currentCategory === category ? "primary" : "outline-secondary"
              }
              onClick={() => setCurrentCategory(category)}
              key={category}
            >
              {category}
            </Button>
          ))}
        </ButtonGroup>
      </ButtonToolbar>
      <div className="card-container">
        {items.map(
          (item) =>
            (currentCategory === "all" || item.category === currentCategory) &&
            (currentStore === "all" ||
              (currentStore === "migros" &&
                item.stores &&
                item.stores.migros) ||
              (currentStore === "coop" && item.stores && item.stores.coop)) && (
              <MyCard item={item} uid={uid} key={item.id} />
            )
        )}
      </div>
    </div>
  );
}

function MyCard({ item, uid }) {
  const [currentRating, setCurrentRating] = useState(
    item.ratings && item.ratings[uid] ? item.ratings[uid] : 0
  );

  const deleteItem = async (id) => {
    const itemDoc = doc(db, "items", id);
    await deleteDoc(itemDoc);
    window.location.reload();
  };

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img
        variant="top"
        src={item.imglink}
        className="p-3 object-contain"
        style={{ width: "18rem", height: "18rem" }}
      />
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">{item.brand}</Card.Subtitle>
        <Card.Title>{item.name}</Card.Title>
        <Card.Text>
          Bewertung:{" "}
          {item.ratings
            ? Object.values(item.ratings).reduce((a, b) => a + b, 0) /
              Object.values(item.ratings).length
            : "-"}
          <br />
          Kategorie: {item.category}
          <br />
          Verfügbar in {item.stores &&
            (item.stores.migros ? "Migros" : "")}{" "}
          {item.stores && (item.stores.coop ? "Coop" : "")}
        </Card.Text>
        {uid === "T0HxYttKRVMmxyfrdHy4LsuAH9B2" && (
          <Button variant="primary" onClick={() => deleteItem(item.id)}>
            Löschen
          </Button>
        )}
        <ButtonGroup>
          {uid !== "" &&
            [1, 2, 3, 4, 5].map((rating) => (
              <RatingButton
                item={item}
                uid={uid}
                rating={rating}
                currentRating={currentRating}
                setCurrentRating={setCurrentRating}
                key={rating}
              />
            ))}
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
}

function RatingButton({ item, uid, rating, currentRating, setCurrentRating }) {
  const addRating = async (itemid, uid, rating) => {
    setCurrentRating(rating);
    const itemDoc = doc(db, "items", itemid);
    await updateDoc(itemDoc, {
      ["ratings." + uid]: rating,
    });
  };

  return (
    <Button
      variant={currentRating === rating ? "primary" : "outline-secondary"}
      onClick={() => addRating(item.id, uid, rating)}
    >
      {rating}
    </Button>
  );
}
