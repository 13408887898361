import "./App.css";
import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";

//import './custom-css-bootstrap-magic-2024-02-18.css';
import "bootstrap/dist/css/bootstrap.min.css";

import { collection, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import { auth, db } from "./firebase";

import { MyHeader } from "./MyHeader";
import { MyItemList } from "./MyItemList";
import { MyForm } from "./MyForm";

function App() {
  const [items, setItems] = useState([]);
  const [user, setUser] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDocs(collection(db, "items"));
      setItems(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchData();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (newUser) => {
      if (newUser) {
        setUser(newUser);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleShow = () => setShowForm(true);
  const handleClose = () => setShowForm(false);

  return (
    <div className="App">
      <link
        href="https://fonts.googleapis.com/css?family=Poppins"
        rel="stylesheet"
      ></link>
      <MyHeader user={user} />
      <MyItemList items={items} uid={user ? user.uid : ""} />
      <MyForm
        showForm={showForm}
        handleClose={handleClose}
        uid={user ? user.uid : ""}
      />
      {user !== null && (
        <Button
          variant="primary"
          className="fixed z-90 bottom-8 right-8 rounded-full shadow-sm"
          onClick={handleShow}
        >
          Neues Produkt
        </Button>
      )}
    </div>
  );
}

export default App;
