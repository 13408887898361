import "./App.css";
import { useState } from "react";

import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import ToggleButton from "react-bootstrap/ToggleButton";

import { collection, addDoc } from "firebase/firestore";

import { db } from "./firebase";

import { allCategories } from "./constants";

export function MyForm({ showForm, handleClose, uid }) {
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [imglink, setImglink] = useState("");
  const [category, setCategory] = useState("other");
  const [migros, setMigros] = useState(false);
  const [coop, setCoop] = useState(false);

  const addItem = async () => {
    await addDoc(collection(db, "items"), {
      name: name,
      brand: brand,
      imglink: imglink,
      category: category,
      stores: { migros: migros, coop: coop },
    });
    window.location.reload();
  };
  return (
    uid !== "" && (
      <Modal show={showForm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Produkt Hinzufügen</Modal.Title>
        </Modal.Header>
        <div className="w-full flex justify-center items-center">
          <div className="w-full max-w-sm p-10">
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Name
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    onChange={(event) => setName(event.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Brand
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Enter brand"
                    onChange={(event) => setBrand(event.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Image
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Enter image link"
                    onChange={(event) => setImglink(event.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Select
                onChange={(event) => setCategory(event.target.value)}
                className="mb-3"
              >
                <option>Kategorie</option>
                {allCategories.map((category) => (
                  <option value={category} key={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              <div className="mb-3">
                <ButtonGroup className="mb-2">
                  <ToggleButton
                    id="toggle-check"
                    type="checkbox"
                    variant={migros ? "primary" : "outline-secondary"}
                    value="1"
                    checked={migros}
                    onChange={(event) => setMigros(event.currentTarget.checked)}
                  >
                    <img
                      alt="migros-logo"
                      className="object-contain"
                      style={{
                        width: "5rem",
                        height: "2rem",
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Migros.svg/1280px-Migros.svg.png"
                    />
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant={coop ? "primary" : "outline-secondary"}
                    id="coop"
                    value="2"
                    checked={coop}
                    onChange={(event) => setCoop(event.currentTarget.checked)}
                  >
                    <img
                      alt="coop-logo"
                      className="object-contain"
                      style={{
                        width: "5rem",
                        height: "2rem",
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Coop.svg/2560px-Coop.svg.png"
                    />
                  </ToggleButton>
                </ButtonGroup>
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  addItem();
                  handleClose();
                }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Modal>
    )
  );
}
