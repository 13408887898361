import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "./logo.png";
import "./App.css";

import Button from "react-bootstrap/Button";

import { signInWithGoogle, signOutUser } from "./firebase";

export function MyHeader({ user }) {
  return (
    <Navbar className="shadow mb-3">
      <Container>
        <Navbar.Brand href="#home">
          <div className="flex">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top mr-2"
              alt=""
            />{" "}
            <div className="hidden sm:block">Mäge's Vegi-Tracker</div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {user === null && (
            <Button variant="primary" onClick={signInWithGoogle}>
              Sign In With Google
            </Button>
          )}
          {user !== null && (
            <>
              <Navbar.Text className="mr-3 text-xs sm:text-base">
                Signed in as: {user.displayName}
              </Navbar.Text>
              <Button variant="primary" onClick={signOutUser}>
                Sign out
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
