import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAAQYygQ3Lg66qx4OFUyDPL3J-pswZVWbo",
  authDomain: "vegi-tracker.firebaseapp.com",
  projectId: "vegi-tracker",
  storageBucket: "vegi-tracker.appspot.com",
  messagingSenderId: "423673411794",
  appId: "1:423673411794:web:87ec889f6234dc840f550b",
  measurementId: "G-XNX1312BVF",
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const auth = getAuth();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      console.log("Sign out successful");
    })
    .catch((error) => {
      console.log(error);
    });
};
