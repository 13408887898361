export const allCategories = [
  "schnitzel",
  "nuggets",
  "burger",
  "bratwurst",
  "geschnetzeltes",
  "hack",
  "pouletfilet",
  "steak",
  "speck",
  "aufschnitt",
  "other",
];
